import Vue from 'vue'
import VueRouter from 'vue-router'
import { isAuthorized } from '@/libs/acl/authorization'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        loginRequired: true,
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/profile/AccountSettings.vue'),
      loginRequired: true,
      pageTitle: 'Account Setting',
      resource: 'account_setting',
      action: 'view',
      breadcrumb: [
        {
          text: 'Account Setting',
          active: true,
        },
      ],
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('@/views/search/Search.vue'),
      loginRequired: true,
    },
    {
      path: '/share',
      name: 'share',
      component: () => import('@/views/search/Share.vue'),
      loginRequired: true,
    },
    {
      path: '/bookings/flight',
      name: 'flight-bookings',
      component: () => import('@/views/bookings/flight/FlightBookingList.vue'),
      meta: {
        loginRequired: true,
        pageTitle: 'Flight Bookings',
        breadcrumb: [
          {
            text: 'Flight Bookings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/employees',
      name: 'employees',
      component: () => import('@/views/users/Employees.vue'),
      loginRequired: true,
      resource: 'employees',
      action: 'view',
      meta: {
        pageTitle: 'Employees',
        breadcrumb: [
          {
            text: 'Employees',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/list',
      name: 'users',
      component: () => import('@/views/users/UserList.vue'),
      loginRequired: true,
      resource: 'users',
      action: 'view',
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/departments',
      name: 'departments',
      component: () => import('@/views/users/DepartmentList.vue'),
      loginRequired: true,
      resource: 'departments',
      action: 'view',
      meta: {
        pageTitle: 'Departments',
        breadcrumb: [
          {
            text: 'Departments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts/invoice',
      name: 'invoices',
      component: () => import('@/views/accounts/invoice/InvoiceList.vue'),
      loginRequired: true,
      resource: 'invoices',
      action: 'view',
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Invoices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts/wallet/deposit',
      name: 'deposit',
      component: () => import('@/views/accounts/wallet/WalletDeposit.vue'),
      loginRequired: true,
      meta: {
        pageTitle: 'Credit Deposit',
        breadcrumb: [
          {
            text: 'Deposit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accounts/wallet/credit',
      name: 'credit',
      component: () => import('@/views/accounts/wallet/WalletRequest.vue'),
      loginRequired: true,
      meta: {
        pageTitle: 'Credit Request',
        breadcrumb: [
          {
            text: 'Credit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/booking-list/all-trips',
      name: 'all',
      component: () => import('@/views/bookings/flight/TripList.vue'),
      loginRequired: true,
      meta: {
        pageTitle: 'All Trips',
        breadcrumb: [
          {
            text: 'All Trips',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bookings/hotel',
      name: 'hotel-bookings',
      component: () => import('@/views/bookings/hotel/HotelBookingList.vue'),
      loginRequired: true,
      meta: {
        pageTitle: 'Hotel Bookings',
        breadcrumb: [
          {
            text: 'Hotel Bookings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bookings/bus',
      name: 'bus-bookings',
      component: () => import('@/views/bookings/bus/BusBookingList.vue'),
      meta: {
        pageTitle: 'Bus Bookings',
        breadcrumb: [
          {
            text: 'Bus Bookings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/accept-invitation',
      name: 'accept-invitation',
      component: () => import('@/views/auth/EmployeeSignup.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, from, next) => {
  if (!isAuthorized(to)) {
    return next({ name: 'login' })
  }
  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
